(function ($, Drupal) {
  Drupal.behaviors.customCollectionMppProductLayoutV1 = {
    attach: function (context) {
      this.context = context;

      this.setElements();
      this.setCarousels();
    },

    setElements: function () {
      this.$grids = $('.js-product-grid', this.context);
    },

    setCarousels: function () {
      var $carouselGrids = this.$grids.filter('.js-product-grid--carousel');
      var $carousels = $('.js-product-carousel', $carouselGrids);
      var hasQuickshop = this.$grids.hasClass('js-product-grid--quickshop');
      var $wrapper = $('.js-custom-collection-product-grid-v1', this.context);

      $carousels.each(function () {
        var $carousel = $(this);
        var slidesToShowCount = $carousel.data('slides-to-show');
        var $parent = $carousel.parent();
        var arrowsDiv = $parent.find('.js-carousel-controls');
        var dotsDiv = $parent.parent().find('.js-carousel-dots');
        var settings = {
          appendArrows: arrowsDiv,
          infinite: true,
          slidesToShow: 3,
          slidesToScroll: 3,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                appendDots: dotsDiv,
                arrows: false,
                dots: true,
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        };

        if (slidesToShowCount) {
          settings.slidesToShow = slidesToShowCount;
        }

        $carousel.slick(settings);

        $carousel.on('beforeChange', function () {
          if (hasQuickshop && Drupal.behaviors.quickshop) {
            $('.js-custom-collection-product-quickshop-v1', $wrapper).remove();
          }
        });
      });
    }
  };
})(jQuery, Drupal);
